import React from "react"
import styled from "styled-components"
import { Row, Col } from "styled-bootstrap-grid"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const options = {
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  },
}

const TopicContainer = styled.div`
  width: 100%;
  padding: 10px 0;
  margin-bottom: 66px;
  box-sizing: border-box;
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`
const TopicMenu = styled(Col)`
  li {
    padding: 6px 0;
  }
`
const TopicTitle = styled.div`
  ${fonts.nhassGroteskBold}
  padding: 6px 4px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.02em;
`

const TopicContents = styled(Col)`
  padding-bottom: 40px;
`
const TopicText = styled.div`
  font-size: 10px;
  line-height: 16px;
  margin: 6px 4px;
  text-align: left;
`

const TopicDesktop = ({ topics }) => {

  return (
    <TopicContainer>
      <Row>
        <TopicMenu xs={12} md={3}>
          <ul>
            {topics.map(({ title, content }, index) => (
              <li key={index}>
                <TopicTitle>
                  {title}
                </TopicTitle>
                <TopicContents xs={12} md={9}>
                  <TopicText>
                    {documentToReactComponents(content.json, options)}
                  </TopicText>
                </TopicContents>
              </li>
            ))}
          </ul>
        </TopicMenu>
      </Row>
    </TopicContainer>
  )
}

export default TopicDesktop
