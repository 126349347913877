import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import CoreContainer from "../components/Core/CoreContainer"
import TopicDesktop from "../components/LegalArea/TopicDesktop"
import TopicMobile from "../components/LegalArea/TopicMobile"
import fonts from "../styles/fonts"

const PageTitle = styled.h1`
  ${fonts.nhassGroteskBold};
  padding: 52px 4px 30px;
  font-size: 14px;
  letter-spacing: 0px;
  width: 100%;
  text-transform: uppercase;
  @media (min-width: ${breakpoints.md}) {
    ${fonts.nhassGroteskRoman};
    font-weight: bold;
    padding: 80px 6px 42px;
    font-size: 16.5px;
    letter-spacing: 1.2px;
  }
`

const ContainerStyled = styled.div`
  @media (min-width: ${breakpoints.md}) {
    min-height: calc(100vh - 80px);
    padding-left: 100px;
    min-height: 650px;
    padding-bottom: 30px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: calc(100vh - 120px);
    padding-left: 168px;
  }
`

const LegalArea = (props) => {
  const path = props.location.pathname
  const page = props.data.page
  const { title, topics } = page

  return (
    <Layout>
      <SEO
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={false}
        path={path}
      />
      <CoreContainer>
        <ContainerStyled>
          <PageTitle>{title}</PageTitle>
          <TopicDesktop topics={topics} />
          <TopicMobile topics={topics} />
        </ContainerStyled>
      </CoreContainer>
    </Layout>
  )
}

export default LegalArea

export const pageQuery = graphql`
  query LegalAreaQuery {
    page: contentfulLegalArea(slug: { eq: "legal-area" }) {
      id
      slug
      metaTitle
      metaDescription
      title
      topics {
        title
        content {
          json
        }
      }
    }
  }
`
