import React, { useState } from 'react'
import styled from "styled-components"
import { Row, Col } from "styled-bootstrap-grid"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const options = {
  renderText: (text) => {
    return text.split("\n").reduce((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
  },
}

const TopicContainer = styled.div`
  padding: 10px 0 0;
  box-sizing: border-box;
  display: none;
  @media(min-width: ${breakpoints.md}) {
    display: block;

  }
`
const TopicMenu = styled(Col)`
  li {
    padding: 6px 0;
  }
`
const TopicTitle = styled.div`
  ${fonts.nhassGroteskLight}
  padding: 6px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 9.23px;
  letter-spacing: 0.02em;
  white-space: nowrap;
  cursor: pointer;
  &.active {
    background-color: ${colors.neonYellow};
    ${fonts.nhassGroteskBold}
  }
`

const TopicContents = styled(Col)`
  padding: 0px 0 6px;
  border-top: 1px solid ${colors.black};
  @media (min-width: ${breakpoints.md}) {
    border-top: none;
  }
`
const TopicText = styled.div`
  max-width: 490px;
  font-size: 9.23px;
  letter-spacing: 0.02em;
  line-height: 16px;
  padding: 9px 6px 0px;
`


const TopicDesktop = ({topics}) => {
  const [openTopic, setOpenTopic] = useState(0)

  const handleOpenTopic = (topicId) => {
    setOpenTopic(topicId)
  }
  return (
    <TopicContainer>
    <Row>
      <TopicMenu xs={12} md={3} lg={1}>
        <ul>
          {topics.map(({ title }, index) => (
            <li key={index}>
              <TopicTitle
                className={index === openTopic && "active"}
                onClick={() => handleOpenTopic(index)}
              >
                {title}
              </TopicTitle>
            </li>
          ))}
        </ul>
      </TopicMenu>
      <TopicContents xs={12} md={9} lg={10} lgOffset={1}>
        {topics.map(
          ({ content }, index) =>
            index === openTopic && (
              <TopicText key={index}>
                {documentToReactComponents(content.json, options)}
              </TopicText>
            )
        )}
      </TopicContents>
    </Row>
  </TopicContainer>
  )
}

export default TopicDesktop

